import React, { useRef, useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import AeroDesk from "../../images/landing/aero.jpg"
import Car from "../../images/landing/car1800.jpg"
import CarTab from "../../images/landing/car1800.jpg"
import CarDesk from "../../images/landing/car1800.jpg"
import propertyTab02 from "../../images/landing/1802.jpg"
import DownTownDesk from "../../images/landing/1803.png"
import Location01 from "../../images/landing/place01.png"
import Location02 from "../../images/landing/place02.png"
import Location03 from "../../images/landing/place03.png"
import Location04 from "../../images/landing/place04.png"
import Location05 from "../../images/landing/place05.jpg"
import { useWindowSize } from "../../hooks/window-size"
import parse from "html-react-parser"

import AreaguideCard from "../AreaguideCard/AreaguideCard"

import "./SelectAreaGuides.scss"
import GenerateLink from "../common/site/GetLink"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"

const SelectAreaGuides = ({ module, currentArea }) => {
  const [windowWidth] = useWindowSize()

  const areaguidesData = useStaticQuery(graphql`
    query GetOtherAreaguides {
      glstrapi {
        areaguides {
          Title
          URL
          id
          Tile_Image {
            url
          }
          imagetransforms
        }
      }
    }
  `)

  const areaguides = areaguidesData.glstrapi.areaguides

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  let filteredAreaguides = []
  if (currentArea != null) {
    filteredAreaguides = areaguides.filter(area => area.id !== currentArea.id)
  } else {
    filteredAreaguides = module.Areaguides
  }

  return (
    <>
      <section className="select-areaguides">
        <div className="select-areaguides-wrap">
          <Container>
            <div className="intro">
              <div className="intro-content">
                <h2 className="heading">{module.Module_Heading}</h2>
                {parse(module.Module_Content)}
              </div>
              <GenerateLink link={"living-in-dubai"} class="btn">
                <span>Living in Dubai</span>
                <i className="icon-right"></i>
              </GenerateLink>
            </div>

            <Row className="areaguide-cards">
              <Slider className="areaguide-slider" {...settings}>
                {filteredAreaguides.map(Areaguide => (
                  <Col className="small-card">
                    <AreaguideCard key={Areaguide.id} newsCard={Areaguide} />
                  </Col>
                ))}
              </Slider>
            </Row>
          </Container>
        </div>
      </section>
    </>
  )
}
export default SelectAreaGuides
