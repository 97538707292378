import React from "react"
import { graphql } from "gatsby"

import Header from "../components/Header/Header"
import Banner from "../components/BannerComp/Banner"
import AreaguidesDetail from "../components/AreaguidesDetail/AreaguidesDetail"
import SEO from "../components/Seo/seo"
import PropertyFeatures from "../components/PropertyFeatures/PropertyFeatures"
import Marker from "../components/MapMarker/Marker"
import GoogleMapReact from "google-map-react"
import Help from "../components/Help/Help"
import SelectAreaGuides from "../components/SelectAreaGuides/SelectAreaGuides"
import Footer from "../components/Footer/Footer"
import SiteBreadcrumbs from "../components/BreadCrumbs/site-breadcrumbs"
import PropertyMap from "../components/PropertyMap/PropertyMap";
import BrandLogo from "../images/logo.svg";

const AreaGuidesDetailPage = props => {
  const areaguide = props.data.glstrapi.areaguide
  const otherAreaguidesData = props.data.glstrapi.globalConfig.Other_Areaguides
  let metatitle = ""+areaguide.Title+" Area Guide"
  let metadesc  = "Know more about "+areaguide.Title+" here. Contact one of our real estate brokers for assistance in finding the right properties for you in "+areaguide.Title+"."
  return (
    <div className="areaguides-detail-page">
      <SEO
        title={areaguide.Meta_Title ? areaguide.Meta_Title : metatitle}
        description={areaguide.Meta_Description ? areaguide.Meta_Description : metadesc}
        image={areaguide?.Banner_Image?.url}
      />
      <Header />
      <Banner
        title={areaguide.Title}
        search={true}
        buttons={false}
        reviews={true}
        overlaybanner={areaguide.Banner_Image}
        // bannerimage={areaguide.Banner_Image}
        imagetransforms={areaguide.imagetransforms}
        id={areaguide.id}
        // video={areaguide.Banner_Video_Link}
        content={areaguide.Banner_Description}
        pageTitle={areaguide.Title}
        type="area-details"
      />
      <AreaguidesDetail areaguide={areaguide} />
      <PropertyFeatures areaname={areaguide.Title} />
      <div className="google-maps">
        {/* <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            key: process.env.GATSBY_MAPS_API_KEY,
          }}
          // bootstrapURLKeys={{ key: "" }}
          defaultCenter={{
            lat: parseFloat(areaguide.Latitude),
            lng: parseFloat(areaguide.Longitude),
          }}
          defaultZoom={11}
        >
          <Marker
            lat={parseFloat(areaguide.Latitude)}
            lng={parseFloat(areaguide.Longitude)}
          />
        </GoogleMapReact> */}
        <PropertyMap lat={parseFloat(areaguide.Latitude)} lng={parseFloat(areaguide.Longitude)}/>
      </div>
      <SelectAreaGuides module={otherAreaguidesData} currentArea={areaguide} />

      <Help module="Lets_Work_Together_Module" />
      <Footer popularsearch= "Sale_Search"/>
    </div>
  )
}

export default AreaGuidesDetailPage

export const pageQuery = graphql`
  query GetAreaGuide($articleId: ID!) {
    glstrapi {
      areaguide(id: $articleId, publicationState: LIVE) {
        Title
        Meta_Title
        Meta_Description
        Select_Contact {
          Designation
          URL
          Email
          id
          Phone
          Name
          imagetransforms
          languages
          Image {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 180
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
        Tile_Image {
          url
        }
        Banner_Image {
          url
        }
        Banner_Description
        Areaguide_Above_Intro {
          Intro_Content_Above
          id
          Intro_Image_Above {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 920
                  height: 540
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
        Areaguide_Tile {
          Tile_CTA {
            CTA_Label
            CTA_Link {
              Secondary_URL
              Primary_URL
              Label
              Show_In_Top_Nav
              id
            }
            CTA_Secondary_Link
            id
          }
          Tile_Content
          Tile_Image {
            url
          }
          Tile_Title
          id
        }
        Areaguide_Below_Intro {
          Intro_Content_Below
          Intro_Content_Below_Last
          Intro_Image_Below {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 920
                  height: 540
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
          id
        }
        Areaguide_FAQ {
          id
          FAQ_List {
            Content
            Title
            id
          }
        }
        id
        imagetransforms
        Latitude
        Longitude
      }

      globalConfig {
        Other_Areaguides {
          id
          Module_Heading
          Module_Content
          Areaguides {
            Title
            URL
            id
            Tile_Image {
              url
            }
            imagetransforms
          }
        }
      }
    }
  }
`
